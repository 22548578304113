var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

var homeQuickGivingPreFooter = true;
// var transformHomepageDonationAmountImages = true;

var feedTitleLinks = ['/blogs/case-studies', '/events', '/pages/news'];

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;


// Run function on resize
$(document).ready(function () {
  headerSocialWidth();
  $(window).resize(function() {
      headerSocialWidth();
  });
});


// Header - Centered logo
function headerSocialWidth() {
  var navBreakpoint = '1180';
  if (window.matchMedia('(min-width:' + navBreakpoint + 'px)').matches) {
    var logoWidth = $('.mainLogo').outerWidth(true);
    var socialIconsWidth = $('.headerContent ul.socialIcons').outerWidth(true);
    var $menuMain = $('.menuMain');
    $menuMain.css({
      'padding-right' : socialIconsWidth + 'px',
      'padding-left' : logoWidth + 'px'
    });
  };
}


$('.homeFeatures .homeFeature').each(function() {
  var summaryText  = $(this).find('p');
  $('<span class="readMore">Read more</span>').appendTo(summaryText);
})

// Stats - With Icon
$(".homefeaturecategory-homeboximpactstats td:has(img)").addClass("containsIcon");